<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import { getNavbar } from "~/utils/base/common-utils";

const {site_url, site_name, site_persian_name} = useRuntimeConfig().public
const navbar = ref(getNavbar());

const scroll = useScroll(49)
</script>

<template>
  <div
      class="flex flex-col items-center w-full transition-all"
      :class="scroll ? 'fixed top-0 inset-x-0 px-4 pt-1 pb-0 bg-primary z-[999]' : ''"
  >
    <div v-if="scroll" class="flex items-center gap-2">
      <u-avatar
          :alt="site_name"
          size="md"
      />
      <span class="text-md text-white tracking-tight">{{site_persian_name}}</span>
    </div>
    <div
        class="w-full bg-white grid border border-gray-200 transition-all duration-75"
        :class="scroll ? 'rounded-full relative top-6' : 'rounded-lg'"
        :style="{ gridTemplateColumns: scroll ? `repeat(${navbar.length}, minmax(0, 1fr))` : 'repeat(2, minmax(0, 1fr))' }"
    >

      <div
          v-for="(nav, index) in navbar"
          :key="index"
          class="flex items-center justify-center"
          :class="{
        'border-b border-gray-200': !scroll && index < navbar.length - 1,
        'border-l border-gray-200': (!scroll && (!index || !(index % 2))) || (scroll && index < navbar.length - 1),
        'p-4' : !scroll,
        'py-2.5': scroll
      }"
      >
        <u-button
            :disabled="index"
            :to="nav.options && nav.options.length ? nav.options[0].slug : nav.slug "
            color="white"
            variant="soft"
            size="xs"
            :leading-icon="nav.icon"
            :ui="{ base: 'text-gray-800 hover:text-primary transition-colors duration-400' }"
        >
          <template v-if="!scroll" #default>
            {{nav.fa_name}}
          </template>
        </u-button>
      </div>
    </div>
  </div>
</template>
