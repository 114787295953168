<script setup lang="ts">
import {defaultCabinType, defaultTripType, handleFlightSearch} from "~/utils/flight/search-utils";
import type {FlightSearchForm} from "~/types/flight/search/FlightSearchForm";
import type {FaqItem} from "~/types/seo/FaqItem";
import {
    getMeta,
    getOrganizationSchema,
    makeFaqs,
    setBreadcrumbSchema, setFaqSchema,
    setWebPageSchema, siteConfig
} from "~/utils/content/seo-utils";
import type {SeoItem} from "~/types/seo/SeoItem";


// بهینه‌سازی `useDevice()`
const deviceState = useState('deviceState', () => useDevice());
const isMobileOrTablet = computed(() => deviceState.value.isMobileOrTablet);
const isDesktop = computed(() => deviceState.value.isDesktop);

// بهینه‌سازی `state`
const state = reactive({
    tripType: defaultTripType(),
    cabinType: defaultCabinType()
});

// استفاده از `useFetch()` با کش داخلی و `getMeta()` در `SSR`
const {data: content} = await useFetch('/api/v1/page', {
    key: 'home',
    headers: {
        'x-path-item': 'home',
        'Cache-Control': 'public, max-age=3600, stale-while-revalidate=86400'
    },
    transform: (input: any) => {
        // اجرای `getMeta()` در سرور
        return {
            ...input,
            fetchedAt: new Date()
        };
    },
    getCachedData: (key, nuxtApp) => {
        const cachedData = nuxtApp.payload.data[key] || nuxtApp.static.data[key];
        if (!cachedData) return;

        // اعتبارسنجی کش (۳۰ دقیقه)
        const isExpired = useCheckExpiration(cachedData.fetchedAt, 60);
        return isExpired ? undefined : cachedData;
    }
});
getMeta(content.value);
const faqs = computed(() => content.value && content.value.faqs && content.value.faqs.length ? makeFaqs(content.value.faqs) : [])


useSchemaOrg([
    setBreadcrumbSchema([{ name: siteConfig().site_persian_name, item: '/' }]), // Breadcrumb
    setWebPageSchema(content.value?.title || 'Travel Search Engine', !!content.value?.faqs?.length), // WebPage
    getOrganizationSchema(), // اطلاعات سایت
    // getSearchActionSchema(), // قابلیت جستجو
    ...(setFaqSchema(content.value?.faqs) ?? [])
])


// مدیریت جستجو
const handleSearch = (search: FlightSearchForm) => handleFlightSearch(search);

</script>


<template>
    <nuxt-layout>
        <div class="w-full flex flex-col gap-4 md:pt-16 px-2 md:px-0">
            <h1 class="w-[1px] h-[1px] overflow-hidden -m-1">{{ content.title }}</h1>
            <div class="w-full flex flex-col bg-white rounded-xl">
                <search-box-top-component v-if="isDesktop">

                    <div class="w-full flex flex-col py-10 px-5 gap-5">
                        <div class="flex items-center gap-3">
                            <div class="w-fit flex border-b">
                                <lazy-flight-search-trip-type-component
                                    v-model="state.tripType"
                                />
                            </div>
                            <div>
                                <lazy-flight-search-cabin-type-component
                                    v-model="state.cabinType"
                                />
                            </div>
                        </div>
                        <lazy-flight-search-box-component
                            :trip-type="state.tripType"
                            @handle-search="handleSearch"
                        />
                    </div>

                </search-box-top-component>
                <div v-else class="w-full">
                    <search-box-small-component/>
                </div>
            </div>
            <lazy-common-action-partner-ship-component/>
            <home-content-component
                v-if="isDesktop"
                :content="content.contents"
                :faqs="faqs"
                :anchors="content.anchors"
            />
            <home-small-content-component
                v-else
                :content="content.contents"
                :faqs="faqs"
                :anchors="content.anchors"
            />
        </div>
    </nuxt-layout>
</template>
